<template>
  <div class="app-contact">
    <div class="d-flex justify-content-between align-items-center">
      <h4>{{ value[id].role.name }}</h4>
      <button
        type="button"
        class="btn btn-clean"
        @click="onDelete"
        v-if="canDelete"
      >
        <i class="far fa-trash"></i> Sil
      </button>
    </div>

    <div class="d-flex align-items-center mb-3" v-if="false">
      <p-checkbox
        id="sameAsPrevious"
        v-model="value[id].sameAsPrevious"
        :binary="true"
        @change="onInput"
      ></p-checkbox>
      <label for="sameAsPrevious" style="margin-bottom:0; margin-left:10px;"
        >{{ previousContactRole }} ile aynı kişi</label
      >
    </div>
    <template v-if="!value[id].sameAsPrevious">
      <app-form-group
        v-if="canDelete"
        :id="id"
        :validator="$val"
        name="role"
        label="Firmadaki Görevi"
      >
        <p-dropdown
          style="width:100%;"
          v-model="value[id].role"
          :options="roles"
          name="role"
          :showClear="false"
          dataKey="value"
          scrollHeight="270px"
          optionLabel="name"
          placeholder="Kişinin firmadaki görevini belirtiniz"
          :readonly="value[id].sameAsPrevious"
        />
      </app-form-group>

      <app-form-group
        :id="id"
        name="fullName"
        :validator="$val"
        label="Ad Soyad"
      >
        <input
          :id="'fullName' + id"
          name="fullName"
          class="form-control"
          :placeholder="value[id].role.name + ' adı soyadı'"
          v-model.trim.lazy="value[id].fullName"
          @input="onInput"
          :readonly="value[id].sameAsPrevious"
          @keypress="isLetter"
        />
      </app-form-group>
      <app-form-group
        :id="id"
        name="identityNumber"
        :validator="$val"
        label="T.C. Kimlik No"
      >
        <input
          :id="'identityNumber' + id"
          name="identityNumber"
          class="form-control"
          :placeholder="value[id].role.name + ' T.C. Kimlik Numarası'"
          maxlength="11"
          v-numeric
          v-model.trim.lazy="value[id].identityNumber"
          @input="onInput"
          :readonly="value[id].sameAsPrevious"
        />
      </app-form-group>
      <app-form-group
        :id="id"
        name="gsm"
        :validator="$val"
        label="Cep telefonu"
      >
        <p-input-mask
          :id="'gsm' + id"
          name="gsm"
          :placeholder="value[id].role.name + ' cep telefonu numarası'"
          mask="(999) 999 99 99"
          style="width:100%"
          v-model.trim.lazy="value[id].gsm"
          @change="onInput"
          :readonly="value[id].sameAsPrevious"
        ></p-input-mask>
      </app-form-group>
      <app-form-group
        :id="id"
        name="email"
        :validator="$val"
        label="E-posta adresi"
      >
        <input
          :id="'email' + id"
          name="email"
          class="form-control"
          :placeholder="value[id].role.name + ' e-posta adresi'"
          maxlength="100"
          v-model.trim.lazy="value[id].email"
          @input="onInput"
          :readonly="value[id].sameAsPrevious"
        />
      </app-form-group>
    </template>
  </div>
</template>

<script>
import PInputMask from "primevue/inputmask";
import PCheckbox from "primevue/checkbox";
import { reactive } from "vue";
import { settings } from "../shared";

const defaultValue = {
  role: null,
  fullName: null,
  identityNumber: null,
  gsm: null,
  email: null,
  sameAsPrevious: null,
  index: 0,
  canDelete: false
};

export default {
  components: {
    PInputMask,
    PCheckbox
  },
  props: {
    modelValue: Object,
    validator: Object,
    allContacts: {},
    id: {
      type: String,
      required: true
    }
  },
  emits: ["update:modelValue", "input", "delete"],
  setup(props) {
    const value = reactive({
      [props.id]: props.modelValue || { ...defaultValue }
    });

    const previousContactIndex = (value[props.id]?.index || 0) - 1;

    const previousContact =
      previousContactIndex >= 0 && props.allContacts
        ? props.allContacts["c" + previousContactIndex]
        : null;

    return {
      value,
      previousContact,
      roles: settings.validRoles
    };
  },
  computed: {
    $val() {
      return this.validator || this.$v;
    },
    previousContactRole() {
      return this.previousContact?.role?.name ?? "Bir önceki ";
    },
    canDelete() {
      return this.value[this.id].canDelete;
    }
  },
  methods: {
    onInput($event) {
      const value = this.value[this.id];
      this.$emit("update:modelValue", value);
      this.$emit("input", $event, value);
    },
    onDelete() {
      this.$emit("delete", this.value[this.id]);
    }
  },
  watch: {
    previousContact: {
      deep: true,
      handler: function() {
        const value = this.value[this.id];

        if (value.sameAsPrevious) {
          const newValue = { ...this.previousContact };

          newValue.role = { ...value.role };
          newValue.sameAsPrevious = value.sameAsPrevious;
          newValue.index = value.index;

          this.$emit("update:modelValue", newValue);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.app-contact {
  margin-bottom: 50px;
  margin-top: 50px;

  .p-inputmask[readonly] {
    background-color: #e9ecef;
  }
}
</style>
