<template>
  <app-card-wizard title="Firma Yetkilileri">
    <template #description>
      <p>
        Başvuruya konu olan firmada aşağıda belirtilen pozisyonlardaki kişilerin
        bilgilerini giriniz.
      </p>
    </template>
    <section class="form-section">
      <template v-for="(c, key) in value" :key="c">
        <app-contact-info
          :validator="validator"
          :id="key"
          v-model="value[key]"
          @input="updateContacts"
          @delete="deleteContact"
          :allContacts="value"
        ></app-contact-info>
      </template>
      <button type="button" @click="addNewContact" class="btn btn-clean">
        <i class="far fa-plus"></i> Yeni Yetkili Ekle
      </button>
    </section>
    <app-wizard-buttons
      :next-button-disabled="isLoading"
      :next-button-is-loading="isLoading"
      :next-button-text="nextButtonText"
      @next-button-click="onSubmit"
      :prev-button-visible="prevButtonVisible"
      :prev-button-disabled="isLoading"
      @prev-button-click="goTo(-1)"
    ></app-wizard-buttons>
  </app-card-wizard>
</template>

<script>
import { mapGetters } from "vuex";
import { roles, rules } from "../shared";
import AppContactInfo from "../components/ContactInfo.vue";

const createContact = (contact, role, index) => {
  const c = contact
    ? { ...contact }
    : {
        index,
        fullName: null,
        identityNumber: null,
        gsm: null,
        email: null,
        role: null,
        sameAsPrevious: false
      };

  if (role) c.role = role;

  return c;
};

export default {
  components: {
    AppContactInfo
  },
  data() {
    return {
      isLoading: false,
      sameAsPrevious: false,
      value: {}
    };
  },
  validations() {
    const validations = {
      value: {}
    };

    Object.keys(this.value).forEach((key) => {
      if (!this.value[key].sameAsPrevious) {
        validations.value[key] = {
          role: rules.required,
          fullName: rules.required,
          identityNumber: rules.identityNumber,
          gsm: rules.gsm,
          email: rules.email
        };
      }
    });

    return validations;
  },
  methods: {
    onSubmit() {
      this.validator.$touch();

      if (this.validator.$invalid) {
        this.scrollToError();
        return;
      }

      this.isLoading = true;

      setTimeout(() => {
        this.updateContacts();

        this.markStepAsCompleted(this.stepName);
        this.isLoading = false;

        this.goTo("/notification-settings/" + this.applicationData.stateId);
      }, 500);
    },
    updateContacts() {
      const contacts = [];

      Object.keys(this.value).forEach((key) => {
        contacts.push(this.value[key]);
      });

      this.updateApplicationData({ contacts });
    },
    addNewContact() {
      const index = Object.keys(this.value).length;

      this.value["c" + index] = createContact(
        {
          index,
          canDelete: true
        },
        {
          name: "Yetkili " + (index + 1),
          value: "diger"
        },
        index
      );
    },
    deleteContact(val) {
      delete this.value["c" + val.index];
      console.log(val);
    }
  },
  computed: {
    prevButtonVisible() {
      return !this.applicationData.fromReview;
    },
    nextButtonText() {
      return this.applicationData.fromReview ? "Kaydet" : "Devam Et";
    },
    stepName() {
      return "company-contacts";
    },
    validator() {
      return this.$v;
    },
    ...mapGetters({
      applicationData: "getApplicationData"
    })
  },
  beforeMount() {
    const rc =
      this.applicationData.companyInfo?.companyType?.requiredContacts || [];

    this.value = {};

    const contacts = this.applicationData.contacts || [];

    for (let i = 0; i < rc.length; i++) {
      const role = rc[i];

      const contact = contacts.find((c) => c.role.value === role);

      this.value["c" + i] = createContact(
        contact,
        {
          name: roles[role],
          value: role
        },
        i
      );

      this.updateContacts();
    }

    this.markStepAsCompleted(this.stepName);
  }
};
</script>

<style></style>
